import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractAuthService,
  AbstractEntityTypeService,
  ArrayUtilityService,
  BaseActionKey,
  DynamicFormFieldChangeOutput,
  EntityType,
  ExecutedAction,
  ExecutedActionBehaviour,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  ObjectsUtilityService,
  User,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Resource, ResourceTypes } from 'src/app/resources/Models/resource';
import { ResourceService } from 'src/app/resources/services/resource.service';
import { Department } from '../../../../../Core/models/department.model';
import { DepartmentService } from '../../../../../Core/services/department-service/department.service';
import { RoleNames } from '../../../../../Utilities/helpers';
import { Ticket } from '../../../../models/ticket.model';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent implements OnInit {
  public entityTypeName: string = 'ticket';
  @Input() public entity: Ticket;
  public entityType: EntityType;
  public viewMode: ViewMode = ViewMode.Read;
  public dataIsReady: boolean = false;
  public actionBehaviour: ExecutedActionBehaviour = new ExecutedActionBehaviour(
    { redirectToList: false, reloadData: false, changeViewModeToRead: false }
  );

  public showTabRelations: boolean = true;
  @Input() public showTabNotes: boolean = false;

  @Input() formInDialog: boolean = true;

  @Input() defaultTabKey: string = null;

  @Output() executedActionOutput: EventEmitter<ExecutedAction> =
    new EventEmitter<ExecutedAction>();

  public user: User;

  public isDepartmentManager: boolean = false;

  private optionsDepartment: Department[] = [];
  showTabDocuments: boolean = true;

  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private authService: AbstractAuthService,
    private resourceService: ResourceService,
    private departmentService: DepartmentService,
    private arrayUtilityService: ArrayUtilityService,
    private cd: ChangeDetectorRef
  ) {
    this.user = this.authService.getLoggedUser();

    if (this.departmentService.currentUserIsDepartmentManager()) {
      this.isDepartmentManager = true;
    }
  }

  async ngOnInit() {
    if (this.formInDialog) {
      this.showTabNotes = this.dynamicDialogConfig?.data['showNotes'];
      this.entity = this.dynamicDialogConfig?.data['entity'];
      this.defaultTabKey = this.dynamicDialogConfig?.data['defaultTabKey'];
    }

    await this.getTicktetEntityType();

    if (this.entity != null) {
      if (
        this.entity.ticketUsers != null &&
        this.entity.ticketUsers.length > 0
      ) {
        const aux = [];

        this.entity.ticketUsers.forEach((x) => {
          aux.push({
            id: x.userId,
            name: x.name,
          });
        });
        this.entity.ticketUsers = aux;
      }

      if (this.entity.id == null) {
        this.viewMode = ViewMode.Edit;
      }

      if (this.entity.rootResourceId != null) {
        this.updateResourcesTreeSelectParentId(this.entity.rootResourceId);
      }
    }
  }

  private async getTicktetEntityType(): Promise<void> {
    this.entityType = this.objectsUtilityService.clone(
      await this.entityTypeService.getAllEntityTypeDataByName(
        this.entityTypeName
      )
    );

    await this.updatePropertiesGuiSettings();
  }

  private async getResources(): Promise<Resource[]> {
    const filterGroup: FilterGroup = new FilterGroup();
    filterGroup.filterCollections = [
      new Filter({
        propertyName: 'ResourceTypeId',
        filterOperation: FilterOperations.EqualTo,
        filterExpression: FilterExpressions.And,
        value: ResourceTypes.Park,
      }),
    ];

    if (
      this.authService
        .getLoggedUser()
        .workspaceTokens?.token?.role.includes(RoleNames.Manager)
    ) {
      return (
        await this.entityTypeService.getEntityTypeElementsMinimalList(
          'resource',
          false,
          null,
          filterGroup
        )
      ).entity;
    } else if (
      this.authService
        .getLoggedUser()
        .workspaceTokens?.token?.role.includes(RoleNames.Client)
    ) {
      return await this.resourceService.getResoucesByClientId(filterGroup);
    }
    return [];
  }

  private async updatePropertiesGuiSettings(): Promise<void> {
    const userRoles =
      this.authService.getLoggedUser().workspaceTokens?.token?.role;

    if (this.entity.id != null) {
      const aux = this.entityType.properties.find(
        (x) => x.name == 'ticketTypeId'
      );
      if (aux != null) {
        aux.guiSettings =
          this.utilityService.updateGuiSettingsMultipleProperties(
            aux.guiSettings,
            new Map<string, any>([
              ['enableExpression', 'false'],
              ['showClear', false],
            ])
          );
      }
    }

    const aux = this.entityType.properties.find((x) => x.name == 'isPublic');
    if (aux != null) {
      aux.guiSettings = this.utilityService.updateGuiSettingsProperty(
        aux.guiSettings,
        'visibleExpression',
        'this.form.get("ticketTypeId").value=="tickettypes.ticket"'
      );
    }

    if (
      userRoles?.includes(RoleNames?.Manager) ||
      userRoles.includes(RoleNames?.DepartmentManager)
    ) {
      const aux = this.entityType.properties.find(
        (x) => x.name == 'ticketUsers'
      );
      aux.guiSettings = this.utilityService.updateGuiSettingsMultipleProperties(
        aux.guiSettings,
        new Map<string, any>([['showClear', true]])
      );
    }

    if (userRoles?.includes(RoleNames.Client)) {
      await this.updateGuiSettingsForClient();
    }

    if (this.isDepartmentManager) {
      this.showTabRelations = false;
      this.updateGuiSettingsForDepartmentManager(this.entity);
    }
    this.dataIsReady = true;
  }

  private async updateGuiSettingsForClient() {
    this.showTabRelations = false;

    const rootResourceIdGuiSettingsProperty = this.entityType.properties.find(
      (p) => p.name === 'rootResourceId'
    );

    const resourceIdGuiSettingsProperty = this.entityType.properties.find(
      (p) => p.name === 'resourceId'
    );

    const resources = await this.getResources();
    rootResourceIdGuiSettingsProperty.guiSettings =
      this.utilityService.updateGuiSettingsMultipleProperties(
        rootResourceIdGuiSettingsProperty.guiSettings,
        new Map<string, any>([
          ['options', resources],
          ['referenceType', null],
          ['referenceName', null],
          ['fieldDependencies', null],
          ['filterGroup', null],
          ['fieldParentClass', 'field-container-dynamic-form-2-col'],
        ])
      );
    resourceIdGuiSettingsProperty.guiSettings =
      this.utilityService.updateGuiSettingsMultipleProperties(
        resourceIdGuiSettingsProperty.guiSettings,
        new Map<string, any>([
          ['fieldParentClass', 'field-container-dynamic-form-2-col'],
        ])
      );
    if (
      this.entity != null &&
      this.entity.id != null &&
      this.entity.createdBy != this.authService.getLoggedUser().id
    ) {
      this.entityType.operations = this.entityType.operations.filter(
        (x) => x.name != 'delete' && x.name != 'edit' && x.name != 'update'
      );
    }

    const auxFieldsGuiSettingsToDisable = this.entityType.properties.filter(
      (x) =>
        x.name == 'ticketTypeId' ||
        x.name == 'ticketGroupId' ||
        x.name == 'ticketUsers' ||
        x.name == 'dueDate' ||
        x.name == 'isPublic' ||
        x.name == 'ticketStateId' ||
        x.name == 'checklist' ||
        x.name == 'closeTicketOnChecklistCompleted' ||
        x.name == 'departmentId' ||
        x.name == 'tags'
    );

    if (
      auxFieldsGuiSettingsToDisable != null &&
      auxFieldsGuiSettingsToDisable.length > 0
    ) {
      auxFieldsGuiSettingsToDisable.forEach((x) => {
        if (x.name == 'ticketTypeId' || x.name == 'isPublic') {
          x.guiSettings = this.utilityService.updateGuiSettingsProperty(
            x.guiSettings,
            'visibleExpression',
            'false'
          );
        } else if (x.name == 'ticketStateId') {
          x.guiSettings = this.utilityService.updateGuiSettingsProperty(
            x.guiSettings,
            'enableExpression',
            'false'
          );
        } else {
          x.guiSettings =
            this.utilityService.updateGuiSettingsMultipleProperties(
              x.guiSettings,
              new Map<string, any>([
                ['visibleExpression', 'false'],
                ['enableExpression', 'false'],
              ])
            );
        }
      });
    }
  }

  private updateResourcesTreeSelectParentId(parentId: string): void {
    const treeSelectResouceField = this.entityType.properties.find(
      (field) => field.name === 'resourceId'
    );

    if (
      treeSelectResouceField != null &&
      treeSelectResouceField.guiSettings != null
    ) {
      treeSelectResouceField.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          treeSelectResouceField.guiSettings,
          'resourceParentId',
          parentId
        );
    }
  }

  public onFieldChangeOutputted(event: DynamicFormFieldChangeOutput): void {
    if (event.field == 'rootResourceId') {
      this.updateResourcesTreeSelectParentId(event.value);
      this.entity = this.objectsUtilityService.clone(event.formEntity);
      this.entity.resourceId = null;
      this.entityType = this.objectsUtilityService.clone(this.entityType);
      this.viewMode = ViewMode.Read;
      this.cd.detectChanges();
      this.viewMode = ViewMode.Edit;
      this.cd.detectChanges();
    }
    if (event.field === 'departmentId') {
      this.entity = this.objectsUtilityService.clone(event.formEntity);
      this.entity.resourceId = null;
      this.entity.ticketUsers = [];
      this.entityType = this.objectsUtilityService.clone(this.entityType);
      this.viewMode = ViewMode.Read;
      this.cd.detectChanges();
      this.viewMode = ViewMode.Edit;
      this.cd.detectChanges();
    }
  }

  public async onExecutedAction(action: ExecutedAction) {
    switch (action.baseAction.key) {
      case BaseActionKey.Cancel:
      case BaseActionKey.Delete:
      case BaseActionKey.Create:
      case 'rejectticket':
        this.dynamicDialogRef.close({ data: action });
        break;
    }
    this.executedActionOutput.emit(action);
  }

  private updateGuiSettingsForDepartmentManager(ticket: Ticket): void {
    if (ticket.id == null || ticket.departmentId != null) {
      const rootResourceIdGuiSettingsProperty = this.entityType.properties.find(
        (p) => p.name === 'rootResourceId'
      );
      rootResourceIdGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          rootResourceIdGuiSettingsProperty.guiSettings,
          'fieldDependencies',
          [
            {
              ignoreNullValueOnDependency: false,
              fieldName: 'departmentId',
              propertyName: 'Departments[Id]',
            },
          ]
        );

      const ticketUsersGuiSettingsProperty = this.entityType.properties.find(
        (p) => p.name === 'ticketUsers'
      );
      ticketUsersGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          ticketUsersGuiSettingsProperty.guiSettings,
          'fieldDependencies',
          [
            {
              ignoreNullValueOnDependency: false,
              fieldName: 'departmentId',
              propertyName: 'Departments[Id]',
            },
          ]
        );
      const departmentGuiSettingsProperty = this.entityType.properties.find(
        (p) => p.name === 'departmentId'
      );
      const mapUpdateGuiSettingsForDepartment = new Map<string, any>([
        ['validators', ['Validators.required']],
      ]);
      const departments: Department[] = this.arrayUtilityService.clone(
        this.departmentService.getUserDepartmentsValue()
      );
      if (ticket.id == null) {
        this.optionsDepartment = departments.filter(
          (x) => x.canCreateTicket == true
        );
        this.optionsDepartment.forEach((x) => {
          x.userProfiles = null;
          x.resources = null;
        });
        if (this.optionsDepartment.length == 1) {
          ticket.departmentId = this.optionsDepartment[0].id;
        }

        mapUpdateGuiSettingsForDepartment.set(
          'filterGroup',
          new FilterGroup({
            filterCollections: [
              new Filter({
                propertyName: 'CanCreateTicket',
                filterOperation: FilterOperations.EqualTo,
                value: 'true',
                startGroup: true,
                filterExpression: FilterExpressions.And,
              }),
            ],
          })
        );
      }
      departmentGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsMultipleProperties(
          departmentGuiSettingsProperty.guiSettings,
          mapUpdateGuiSettingsForDepartment
        );
    }

    if (ticket.id && ticket.createdBy != this.user.id) {
      this.updateGuiSettingsForDates(ticket);

      if (ticket.departmentId == null) {
        const ticketUsersGuiSettingsProperty = this.entityType.properties.find(
          (p) => p.name === 'ticketUsers'
        );
        ticketUsersGuiSettingsProperty.guiSettings =
          this.utilityService.updateGuiSettingsProperty(
            ticketUsersGuiSettingsProperty.guiSettings,
            'enableExpression',
            'false'
          );
      }
    }
  }

  public updateGuiSettingsForDates(ticket: Ticket) {
    const departments: Department[] = this.arrayUtilityService.clone(
      this.departmentService.getUserDepartmentsValue()
    );
    const departmentSelected = departments.find(
      (x) => x.id == ticket.departmentId
    );
    if (departmentSelected == null || !departmentSelected.canEditTicketDates) {
      const dueDateGuiSettingsProperty = this.entityType.properties.find(
        (p) => p.name === 'dueDate'
      );
      dueDateGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          dueDateGuiSettingsProperty.guiSettings,
          'enableExpression',
          'false'
        );
    }
  }
}
